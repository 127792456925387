const apps = [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/apps/user/user-view/UserView.vue'),
    meta: {
      layout: 'content',
      resource: 'Public',
    },
  },
  {
    path: '/users/:id',
    name: 'user-view',
    component: () => import('@/views/apps/user/user-view/UserView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('@/views/apps/user/user-list/Users.vue'),
    meta: {
      layout: 'content',
      resource: 'User',
    },
  },
  {
    path: '/roles',
    name: 'roles',
    component: () => import('@/views/apps/role/Roles.vue'),
    meta: {
      layout: 'content',
      resource: 'Role',
    },
  },
  {
    path: '/states',
    name: 'states',
    component: () => import('@/views/apps/states/States.vue'),
    meta: {
      layout: 'content',
      resource: 'State',
    },
  },
  {
    path: '/permissions',
    name: 'permissions',
    component: () => import('@/views/apps/permission/Permissions.vue'),
    meta: {
      layout: 'content',
      resource: 'Permission',
    },
  },
  {
    path: '/sources',
    name: 'sources',
    component: () => import('@/views/apps/sources/Sources.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/particular_cases',
    name: 'particular_cases',
    component: () => import('@/views/apps/particularCase/ParticularCase'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/colors',
    name: 'colors',
    component: () => import('@/views/apps/colors/Colors.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/activities',
    name: 'activities',
    component: () => import('@/views/apps/activities/Activities'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/statuses',
    name: 'statuses',
    component: () => import('@/views/apps/status/Statuses.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import('@/views/apps/settings/Settings.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/imports',
    name: 'imports',
    component: () => import('@/views/apps/settings/Imports.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/configurations',
    name: 'configurations',
    component: () => import('@/views/apps/settings/Configurations.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/tarifications',
    name: 'tarifications',
    component: () => import('@/views/apps/tarifications/Tarifications.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',

    },
  },
  {
    path: '/formats',
    name: 'formats',
    component: () => import('@/views/apps/formats/Formats.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/printers',
    name: 'printers',
    component: () => import('@/views/apps/printers/Printers.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/destinations',
    name: 'destinations',
    component: () => import('@/views/apps/destinations/Destinations.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/genres',
    name: 'genres',
    component: () => import('@/views/apps/genres/Genres.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/author_types',
    name: 'author_types',
    component: () => import('@/views/apps/authortypes/Types.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },{
    path: '/media_types',
    name: 'media_types',
    component: () => import('@/views/apps/mediatypes/Types.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/author_states',
    name: 'author_states',
    component: () => import('@/views/apps/authorstates/States.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/networks',
    name: 'networks',
    component: () => import('@/views/apps/networks/Networks.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/event_types',
    name: 'event_types',
    component: () => import('@/views/apps/eventtypes/Types.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/professions',
    name: 'professions',
    component: () => import('@/views/apps/professions/Professions.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/origins',
    name: 'origins',
    component: () => import('@/views/apps/origins/Origins.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/countries',
    name: 'countries',
    component: () => import('@/views/apps/countries/Countries.vue'),
    meta: {
      layout: 'content',
      resource: 'Configuration',
    },
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: () => import('@/views/apps/tasks/Tasks.vue'),
    meta: {
      layout: 'content',
      resource: 'Tasks',
    },
  },
  {
    path: '/isbns',
    name: 'isbns',
    component: () => import('@/views/apps/isbns/Isbns.vue'),
    meta: {
      layout: 'content',
      resource: 'isbns',
    },
  },
]

export default apps
